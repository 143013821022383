import { State, StateContext, Action, NgxsOnInit, Actions, ofActionSuccessful } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { EMPTY, throwError } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { NotificationStateModel, defaultState } from './notification.model';
import {
  LoadNotifications,
  MarkAllNotificationsAsRead,
  MarkNotificationAsRead,
  SetNotificationsQueryFilter,
  LoadOlderNotifications,
  ConnectToNotificationHub,
  GetNotificationFromGroup,
  DeleteAllNotification,
  DeleteNotification,
  MarkNotificationsInGroupAsRead,
  GetNotificationGroup,
  GetNotificationSettings,
  UpdateNotificationSettings,
  GetNotificationsStatus,
} from './notification.actions';
import { patch, updateItem, append, removeItem, insertItem } from '@ngxs/store/operators';
import { NotificationService } from '@core/services/api/notification.service';
import { NotificationHubService } from '@core/services/hub/notification-hub.service';
import { NotifierService } from 'ngx-q360-lib';
import { NotificationGroupListModel, NotificationGroupModel, NotificationGroupQueryModel } from 'ngx-q360-lib';
import { notificationGroupQuery, notificationsQuery } from '@core/helpers/query.helper';

@Injectable()
@State<NotificationStateModel>({
  name: 'notification',
  defaults: defaultState,
})
export class NotificationState implements NgxsOnInit {
  private actions$ = inject(Actions);
  private notifierService = inject(NotifierService);
  private notificationHubService = inject(NotificationHubService);
  private notificationService = inject(NotificationService);

  ngxsOnInit(ctx: StateContext<NotificationStateModel>) {
    this.actions$.pipe(ofActionSuccessful(GetNotificationGroup)).subscribe(() => this.updateUnseenCount(ctx));
  }

  @Action(ConnectToNotificationHub)
  public connectToNotificationHub(ctx: StateContext<NotificationStateModel>) {
    this.notificationHubService.connect().subscribe((notifications) => {
      if (notifications.length > 0) {
        this.playNotificationSound();
      }

      const map = notifications.map((notification) => {
        const findGroup = ctx
          .getState()
          .notifications.items.find((item) => item.groupId === notification.notificationGroup);
        if (findGroup) {
          ctx.setState(
            patch({
              notifications: patch({
                items: updateItem(
                  (item) => item.groupId === notification.notificationGroup,
                  patch({ notifications: insertItem(notification, 0) }),
                ),
              }),
            }),
          );

          ctx.setState(
            patch({
              notifications: patch({
                items: updateItem(
                  (item) => item.groupId === notification.notificationGroup,
                  patch({ unseenCount: findGroup.unseenCount + 1, total: findGroup.total + 1 }),
                ),
              }),
            }),
          );
        } else {
          // load the group
          ctx.dispatch(new GetNotificationGroup(notification.notificationGroup));
        }

        this.notifierService.notification(notification, {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      });

      Promise.all(map).then(() => this.updateUnseenCount(ctx, true));
    });
  }

  @Action(LoadNotifications)
  public loadNotifications(ctx: StateContext<NotificationStateModel>) {
    const query = { ...ctx.getState().notificationsQueryFilter };
    if (!query.showSeen) {
      query.pageSize = 1000000;
    }

    // if there is no unseen notifications and unseen mode is active, just remove all notifications
    if (!ctx.getState().notifications.unseenCount && !query.showSeen) {
      ctx.setState(patch({ notifications: patch({ items: [], totalCount: 0, page: 0 }) }));
    }

    // logic for skipping calling endpoint
    // will not load if:
    // - unseenCount is 0 and notification view is showing unseen only
    // - action is called with same page (it can happen since we trigger action on opening dropdown)
    // - notification view is showing unseen only and notifications are already loaded
    // - notification view is showing seen and first page is already loaded
    if (
      (!ctx.getState().notifications.unseenCount && !query.showSeen) ||
      (query.page > 0 && query.page === ctx.getState().notifications.page) ||
      (query.showSeen &&
        query.page === 0 &&
        ctx.getState().notifications.pageSize === 10 &&
        ctx.getState().notifications.items.length) ||
      (!query.showSeen &&
        ctx.getState().notifications.pageSize === 1000000 &&
        ctx.getState().notifications.items.length > 0)
    ) {
      return;
    }

    ctx.patchState({ loading: true });
    return this.notificationService.getNotifications(query).pipe(
      tap((result) => {
        if (!ctx.getState().notifications || ctx.getState().notificationsQueryFilter.page === 0) {
          ctx.patchState({ loading: false, notifications: result });
        } else {
          result.items.forEach((notification: NotificationGroupModel) => {
            if (!ctx.getState().notifications.items.find((c) => c.groupId === notification.groupId)) {
              ctx.setState(patch({ notifications: patch({ items: append([notification]) }) }));
            }
          });
          ctx.setState(patch({ notifications: patch({ page: result.page, pageSize: result.pageSize }) }));
          ctx.patchState({ loading: false });
        }
      }),
      catchError((error) => this.handleError(ctx, error)),
    );
  }

  @Action(GetNotificationFromGroup)
  public loadGroupNotifications(ctx: StateContext<NotificationStateModel>, action: GetNotificationFromGroup) {
    ctx.patchState({ loadingGroupNotifications: true });

    const notificationGroups = ctx.getState().notifications.items;
    const notificationGroup = notificationGroups.find((n) => n.groupId == action.notificationGroupId);

    if (!notificationGroup) {
      return;
    }

    const currentPage = notificationGroup.page + 1;
    const query: NotificationGroupQueryModel = {
      ...notificationGroupQuery,
      groupId: action.notificationGroupId,
      page: currentPage,
    };

    if (notificationGroup.total === notificationGroup.notifications.length) {
      ctx.patchState({ loadingGroupNotifications: false });
      return;
    }

    return this.notificationService.getNotificationFromGroup(query).pipe(
      tap((result) => {
        ctx.setState(
          patch({
            notifications: patch({
              items: updateItem<NotificationGroupModel>(
                (item) => item?.groupId === action.notificationGroupId,
                patch({
                  page: currentPage,
                  total: result.totalCount,
                  notifications: append([...result.items]),
                }),
              ),
            }),
          }),
        );
        ctx.patchState({ loadingGroupNotifications: false });
      }),
      catchError((error) => this.handleError(ctx, error)),
    );
  }

  @Action(GetNotificationGroup)
  loadNotificationGroup(ctx: StateContext<NotificationStateModel>, action: GetNotificationGroup) {
    return this.notificationService.getNotificationGroup(action.groupId).pipe(
      tap((result) => {
        const hasGroup = ctx.getState().notifications.items.find((item) => item.groupId === action.groupId);

        ctx.setState(
          patch({
            notifications: patch({
              items: hasGroup ? updateItem((item) => item.groupId === action.groupId, result) : insertItem(result, 0),
            }),
          }),
        );
      }),
    );
  }

  @Action(LoadOlderNotifications)
  public loadOlderNotifications(ctx: StateContext<NotificationStateModel>): void {
    if (ctx.getState().notifications.totalCount > ctx.getState().notifications.items.length) {
      ctx.dispatch(
        new SetNotificationsQueryFilter({
          ...ctx.getState().notificationsQueryFilter,
          page: ctx.getState().notificationsQueryFilter.page + 1,
        }),
      );
    }
  }

  @Action(SetNotificationsQueryFilter)
  public setNotificationsQueryFilter(ctx: StateContext<NotificationStateModel>, action: SetNotificationsQueryFilter) {
    ctx.patchState({ notificationsQueryFilter: { ...ctx.getState().notificationsQueryFilter, ...action.payload } });
    ctx.dispatch(new LoadNotifications());
  }

  @Action(MarkNotificationAsRead)
  public markNotificationAsRead(ctx: StateContext<NotificationStateModel>, action: MarkNotificationAsRead) {
    if (action.notification.seen) {
      ctx.patchState({ loading: false });
      return;
    }

    return this.notificationService.markNotificationAsRead(action.notification).pipe(
      tap(() => {
        ctx.setState(
          patch({
            notifications: patch({
              items: updateItem(
                (item) => item?.groupId === action.notification.notificationGroup,
                patch({
                  notifications: ctx.getState().notificationsQueryFilter.showSeen
                    ? updateItem((n) => n.id === action.notification.id, patch({ seen: true }))
                    : removeItem((item) => item.id === action.notification.id),
                }),
              ),
            }),
          }),
        );

        let notificationGroup = ctx
          .getState()
          .notifications.items.find((g) => g.groupId === action.notification.notificationGroup);

        if (notificationGroup) {
          notificationGroup = {
            ...notificationGroup,
            unseenCount: notificationGroup.unseenCount - 1,
          };

          ctx.setState(
            patch({
              notifications: patch({
                items: updateItem((item) => item?.groupId === action.notification.notificationGroup, notificationGroup),
              }),
            }),
          );

          if (!ctx.getState().notificationsQueryFilter.showSeen && notificationGroup?.unseenCount === 0) {
            ctx.setState(
              patch({
                notifications: patch({
                  items: removeItem((item) => item?.groupId === action.notification.notificationGroup),
                }),
              }),
            );
          }
        }

        const count = ctx.getState().notifications.items.reduce((acc, item) => acc + item.unseenCount, 0);
        ctx.setState(patch({ notifications: patch({ unseenCount: count }) }));
      }),
      catchError((error) => this.handleError(ctx, error)),
    );
  }

  @Action(MarkNotificationsInGroupAsRead)
  public markNotificationsInGroupAsRead(
    ctx: StateContext<NotificationStateModel>,
    action: MarkNotificationsInGroupAsRead,
  ) {
    const group = ctx.getState().notifications.items.find((item) => item.groupId === action.group.groupId);

    if (group?.unseenCount === 0) {
      return;
    }

    return this.notificationService.markGroupAsRead(action.group.groupId).pipe(
      tap(() => {
        ctx.setState(
          patch({
            notifications: patch({
              items: updateItem(
                (item) => item.groupId === action.group.groupId,
                patch({
                  unseenCount: 0,
                  notifications: action.group.notifications.map((n) => {
                    return { ...n, seen: true };
                  }),
                }),
              ),
            }),
          }),
        );

        !ctx.getState().notificationsQueryFilter.showSeen &&
          ctx.setState(
            patch({
              notifications: patch<NotificationGroupListModel>({
                items: removeItem<NotificationGroupModel>((item) => item?.groupId === action.group.groupId),
              }),
            }),
          );

        const unseenCount = ctx.getState().notifications.items.reduce((acc, item) => acc + item.unseenCount, 0);
        ctx.setState(
          patch({
            notifications: patch({
              unseenCount,
            }),
          }),
        );
      }),
      catchError((error) => this.handleError(ctx, error)),
    );
  }

  @Action(MarkAllNotificationsAsRead)
  public markAllNotificationsAsRead(ctx: StateContext<NotificationStateModel>) {
    return this.notificationService.markAllAsRead().pipe(
      tap(() => {
        if (!ctx.getState().notificationsQueryFilter.showSeen) {
          ctx.setState(patch({ notifications: patch({ totalCount: 0, items: [] }) }));
        } else {
          ctx.getState().notifications.items.forEach((group) => {
            group.notifications.forEach((notif) => {
              ctx.setState(
                patch({
                  notifications: patch({
                    items: updateItem(
                      (item) => item?.groupId === group?.groupId,
                      patch<NotificationGroupModel>({
                        unseenCount: 0,
                        notifications: updateItem(
                          (notification) => notification?.id === notif?.id,
                          patch({ seen: true }),
                        ),
                      }),
                    ),
                  }),
                }),
              );
            });
          });
        }
        ctx.setState(patch({ notifications: patch({ unseenCount: 0 }) }));
      }),
      catchError((error) => this.handleError(ctx, error)),
    );
  }

  @Action(DeleteNotification)
  public deleteNotification(ctx: StateContext<NotificationStateModel>, action: DeleteNotification) {
    const notificationId = action.payload.notificationId;

    if (!notificationId) {
      return EMPTY;
    }

    return this.notificationService.delete(notificationId).pipe(
      tap(() => {
        ctx.setState(
          patch({
            notifications: patch<NotificationGroupListModel>({
              items: updateItem<NotificationGroupModel>(
                (item) => item?.notifications.some((n) => n.id === notificationId),
                patch({
                  notifications: removeItem((item) => item?.id === notificationId),
                }),
              ),
            }),
          }),
        );

        ctx.setState(
          patch({
            notifications: patch({
              items: removeItem((item: any) => item?.notifications.length === 0),
            }),
          }),
        );

        if (ctx.getState().notifications.items.length === 0) {
          ctx.dispatch(new LoadNotifications());
        }
      }),
      catchError((error) => this.handleError(ctx, error)),
    );
  }

  @Action(DeleteAllNotification)
  public deleteAllNotification(ctx: StateContext<NotificationStateModel>) {
    return this.notificationService.deleteAll().pipe(
      tap(() => {
        ctx.dispatch(new SetNotificationsQueryFilter({ ...notificationsQuery }));
        ctx.dispatch(new LoadNotifications());
      }),
    );
  }

  @Action(GetNotificationSettings)
  public getNotificationSettings(ctx: StateContext<NotificationStateModel>) {
    return this.notificationService.getNotificationSettings().pipe(
      tap((result) => ctx.patchState({ settings: result })),
      catchError((error) => this.handleError(ctx, error)),
    );
  }

  @Action(UpdateNotificationSettings)
  public updateNotificationSettings(ctx: StateContext<NotificationStateModel>, action: UpdateNotificationSettings) {
    return this.notificationService.updateNotificationSettings(action.payload).pipe(
      tap((result) => ctx.patchState({ settings: result })),
      catchError((error) => this.handleError(ctx, error)),
    );
  }

  @Action(GetNotificationsStatus)
  public getNotificationsStatus(ctx: StateContext<NotificationStateModel>) {
    return this.notificationService.getNotificationStatus().pipe(
      tap((result) => ctx.setState(patch({ notifications: patch({ unseenCount: result.unseenCount }) }))),
      catchError((error) => this.handleError(ctx, error)),
    );
  }

  private handleError(ctx: StateContext<NotificationStateModel>, err: any): any {
    ctx.patchState({ error: err, loading: false });
    return throwError(err);
  }

  private playNotificationSound(): void {
    const audio = new Audio();
    audio.src = './assets/sounds/notification-sound.mp3';
    audio.load();
    if (audio.paused) {
      void audio.play();
    }
  }

  private updateUnseenCount(ctx: StateContext<NotificationStateModel>, socketUpdate = false) {
    // if notifications were never loaded, increase unseenCount by one, since notification came from socket
    if (!ctx.getState().notifications.items.length && socketUpdate) {
      ctx.setState(patch({ notifications: patch({ unseenCount: ctx.getState().notifications.unseenCount + 1 }) }));
      return;
    }

    ctx.setState(
      patch({
        notifications: patch({
          unseenCount: ctx.getState().notifications.items.reduce((acc, item) => acc + item.unseenCount, 0),
        }),
      }),
    );
  }
}
