import { inject, Injectable } from '@angular/core';
import { Action, Actions, NgxsOnInit, ofActionSuccessful, State, StateContext, Store } from '@ngxs/store';
import {
  defaultState,
  ProjectImageAnalyticsStateModel,
} from '@project/store/image-analytics/project-image-analytics.model';
import {
  AddComment,
  AddFollower,
  BulkUpdateProjectImage,
  CreateImagesLink,
  AddProjectImageTag,
  DeleteProjectImage,
  DeleteProjectImageObject,
  DeleteProjectImages,
  DeleteProjectImageTag,
  GetComments,
  GetImagesByLink,
  GetMoreProjectImages,
  GetProjectImage,
  GetProjectImageAudit,
  GetProjectImages,
  GetProjectImageTags,
  GetProjectImageUploadSession,
  GetSettings,
  GetZipFile,
  GetZipList,
  ProjectImageSearch,
  RemoveComment,
  RemoveFollower,
  RevertProjectImageAudit,
  SaveSettings,
  SetProjectImageSearchQuery,
  SetProjectImagesQuery,
  SetProjectImageUploadSession,
  SetProjectImageUploadSessionQuery,
  SetProjectImageZipsQuery,
  UpdateComment,
  UpdateProjectImage,
  UpdateProjectImageInSession,
  UpdateProjectImageObject,
  UpdateProjectImageStatus,
  UpdateProjectImageTag,
  UpdateSelectedImageIds,
  ZipProjectImages,
  SetLastCheckedId,
  SetLastProjectId,
} from '@project/store/image-analytics/project-image-analytics.actions';
import { catchError, tap } from 'rxjs/operators';
import { EMPTY, firstValueFrom, throwError } from 'rxjs';
import { append, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ProjectImagesService } from '@core/services/api/project-images.service';

import {
  AuditType,
  AzureBlobStorageService,
  BlobStorageSasModel,
  BlobStorageSasService,
  downloadViaLink,
  EntityType,
  ifNotNull,
  NotifierService,
  ProjectImageErrorType,
  ProjectImagesFileList,
  ProjectImagesQuery,
  ProjectImageStatus,
  ProjectSearchImagesQuery,
  VisibilityModel,
  ZipImageStatus,
} from 'ngx-q360-lib';
import { isEqual } from 'es-toolkit';
import { formatQueryFilterPayload } from '@core/helpers/global.helper';
import { ProjectImageUploadHubService } from '@core/services/hub/project-image-upload-hub.service';
import { ProjectSelectors } from '@app/store/project/projects.selectors';
import { GetProject } from '@app/store/project/project.actions';
import { queryFilter } from '@core/helpers/query.helper';
import { CreateProjectTag } from '@project/store/selected-project/selected-project.actions';

@Injectable()
@State<ProjectImageAnalyticsStateModel>({
  name: 'projectImages',
  defaults: defaultState,
})
export class ProjectImageAnalyticsState implements NgxsOnInit {
  private projectImageService = inject(ProjectImagesService);
  private actions$ = inject(Actions);
  private azureBlobStorageService = inject(AzureBlobStorageService);
  private notifierService = inject(NotifierService);
  private projectImageUploadService = inject(ProjectImageUploadHubService);
  private store = inject(Store);
  private blobStorageSasService = inject(BlobStorageSasService);

  ngxsOnInit(ctx: StateContext<ProjectImageAnalyticsStateModel>) {
    this.actions$.pipe(ofActionSuccessful(SetProjectImageSearchQuery)).subscribe((action) => {
      if (!action.dispatch) {
        return;
      }

      ctx.dispatch(new ProjectImageSearch(action.projectId));
      ctx.patchState({
        imagesQuery: { ...ctx.getState().imagesQuery, ...ctx.getState().searchedImagesQuery, page: 0 },
      });
    });

    this.actions$.pipe(ofActionSuccessful(SetProjectImagesQuery)).subscribe((action) => {
      if (!action.dispatch) {
        return;
      }

      ctx.dispatch(new GetProjectImages(action.projectId));
    });

    this.actions$.pipe(ofActionSuccessful(SetProjectImageUploadSessionQuery)).subscribe((action) => {
      ctx.dispatch(new GetProjectImageUploadSession(action.projectId));
    });

    this.actions$.pipe(ofActionSuccessful(SetProjectImageZipsQuery)).subscribe((action) => {
      ctx.dispatch(new GetZipList(action.projectId));
    });

    this.actions$.pipe(ofActionSuccessful(GetProjectImageUploadSession)).subscribe(() => {
      ctx.patchState({
        uploadSessionsQuery: {
          ...ctx.getState().uploadSessionsQuery,
          page: this.getSessionsPageCount(ctx),
        },
      });
    });

    this.actions$.pipe(ofActionSuccessful(SetProjectImageUploadSession)).subscribe((action) => {
      const findSession = ctx
        .getState()
        .uploadSessions.find((ses) => !!ses.medias.find((img) => img.id === action.payload.medias[0].id));

      if (findSession) {
        ctx.setState(
          patch({
            uploadingImages: insertItem({
              sessionId: findSession.id,
              medias: action.payload.medias.map((img) => img.id),
            }),
          }),
        );
      }

      void this.handleFileUpload(ctx, action);
    });

    this.actions$.pipe(ofActionSuccessful(UpdateProjectImageStatus)).subscribe((action) => {
      const findSession = ctx
        .getState()
        .uploadSessions.find((ses) => !!ses.medias.find((img) => img.id === action.imageId));

      if (!findSession) {
        return;
      }

      ctx.setState(
        patch({
          uploadingImages: updateItem(
            (item) => item.sessionId === findSession.id,
            patch({ medias: removeItem((image) => image === action.imageId) }),
          ),
        }),
      );

      const uploadingSession = ctx.getState().uploadingImages.find((session) => session.sessionId === findSession.id);
      if (uploadingSession?.medias.length === 0) {
        ctx.setState(patch({ uploadingImages: removeItem((item) => item.sessionId === findSession.id) }));
      }
    });

    this.actions$
      .pipe(ofActionSuccessful(SaveSettings))
      .subscribe((action) => ctx.dispatch(new GetSettings({ projectId: action.payload.projectId })));

    this.actions$.pipe(ofActionSuccessful(CreateProjectTag)).subscribe((action) => {
      const findTag = (ctx.getState().selectedImage?.tags || []).find((tag) => tag.name === action.tag.name);
      if (findTag) {
        ctx.setState(
          patch({
            selectedImage: ifNotNull(
              patch({
                tags: ifNotNull(append([findTag])),
              }),
            ),
          }),
        );
      }
    });

    this.actions$.pipe(ofActionSuccessful(RevertProjectImageAudit)).subscribe((action: RevertProjectImageAudit) => {
      const { audit } = action;
      switch (audit.entityType) {
        case EntityType.Tag:
          ctx.setState(
            patch({
              selectedImage: ifNotNull(
                patch({
                  tags:
                    audit.auditType === AuditType.Create
                      ? ifNotNull(
                          removeItem(
                            (item) =>
                              item.name === audit.newValue.name && item.confidence === audit.newValue.confidence,
                          ),
                        )
                      : audit.oldValue
                        ? audit.auditType === AuditType.Update
                          ? ifNotNull(
                              updateItem(
                                (item) =>
                                  item.name === audit.newValue.name && item.confidence === audit.newValue.confidence,
                                { ...audit.oldValue },
                              ),
                            )
                          : ifNotNull(append([{ ...audit.oldValue }]))
                        : ctx.getState().selectedImage?.tags,
                }),
              ),
            }),
          );
          break;
        case EntityType.DetectedObject:
          ctx.setState(
            patch({
              selectedImage: ifNotNull(
                patch({
                  detectedObjects:
                    audit.auditType === AuditType.Create
                      ? ifNotNull(
                          removeItem(
                            (item) =>
                              item.name === audit.newValue.name && item.confidence === audit.newValue.confidence,
                          ),
                        )
                      : audit.oldValue
                        ? audit.auditType === AuditType.Update
                          ? ifNotNull(
                              updateItem(
                                (item) =>
                                  item.name === audit.newValue.name && item.confidence === audit.newValue.confidence,
                                { ...audit.oldValue },
                              ),
                            )
                          : ifNotNull(append([{ ...audit.oldValue }]))
                        : ctx.getState().selectedImage?.detectedObjects,
                }),
              ),
            }),
          );

          break;
        case EntityType.Location:
          if (!audit.oldValue) {
            break;
          }

          ctx.setState(
            patch({
              selectedImage: ifNotNull(
                patch({
                  location: audit.oldValue,
                }),
              ),
            }),
          );

          break;

        case EntityType.MediaTakenAt:
          if (!audit.oldValue) {
            break;
          }

          ctx.setState(
            patch({
              selectedImage: ifNotNull(
                patch({
                  mediaTakenAt: audit.oldValue,
                }),
              ),
            }),
          );

          break;
        default:
          return;
      }
    });

    this.actions$.pipe(ofActionSuccessful(GetProject, GetProjectImage, AddFollower, RemoveFollower)).subscribe(() => {
      const followers = ctx.getState().selectedImage?.followers || [];
      const collaborators =
        this.store
          .selectSnapshot(ProjectSelectors.myProjects)
          .find((project) => project.id === ctx.getState().selectedImage?.projectId)?.collaborators || [];

      const visibility: VisibilityModel[] = [];

      if (followers) {
        followers.forEach((f) => {
          const findCollaborator = collaborators.find((c) => c.organizationId === f.user.organizationId);
          const count = followers.filter((ff) => ff.user.organizationId === findCollaborator?.organizationId).length;
          if (findCollaborator) {
            const existingCollaborator = visibility.find((tv) => tv.collaboratorId === findCollaborator.id);
            if (existingCollaborator) {
              existingCollaborator.memberCount = count;
            } else {
              visibility.push({
                collaboratorId: findCollaborator.id,
                photoUrl: findCollaborator.photoUrl || '',
                name: findCollaborator.name,
                memberCount: count,
              });
            }
          }
        });
      }

      ctx.patchState({ imageVisibility: visibility });
    });

    this.projectImageUploadService.getZipMessages$().subscribe(async (message) => {
      if (message) {
        const findZip = ctx.getState().zipFiles.find((file) => file.id === message.id);
        if (!findZip) {
          ctx.setState(
            patch({
              zipFiles: insertItem({
                id: message.id,
                projectId: message.projectId,
                fileSize: 0,
                url: message.url,
                status: ZipImageStatus.Initialized,
                count: 0,
                createdAt: '',
              }),
            }),
          );
          ctx.dispatch(new GetZipFile(message.projectId, message.id));
        }
        ctx.setState(
          patch({
            zipFiles: updateItem((item) => item.id === message.id, patch({ zippedCount: message.zippedCount })),
          }),
        );
      }

      if (message && message?.zippedCount === message?.totalCount) {
        const { url } = await firstValueFrom(this.blobStorageSasService.getSasUrlForProject(message.projectId));
        const projectSas = new URL(url);
        const findFinishedZip = ctx.getState().zipFiles.find((file) => file.id === message.id);
        if (findFinishedZip) {
          ctx.dispatch(new GetZipList(findFinishedZip.projectId));
          downloadViaLink(
            projectSas?.origin +
              '/project-' +
              findFinishedZip.projectId +
              '/' +
              findFinishedZip.url +
              projectSas?.search,
          );
        }
      }
    });
  }

  @Action(ProjectImageSearch, { cancelUncompleted: true })
  public projectImageSearch(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: ProjectImageSearch) {
    const currentQuery = ctx.getState().searchedImagesQuery;
    const newQuery = {
      ...currentQuery,
      yearMonth: currentQuery.yearMonth.map((ym) => {
        if (ym === 'nodate') {
          return null;
        }
        return ym;
      }),
    } as ProjectSearchImagesQuery;

    if (newQuery.withLocation === 'all') {
      delete newQuery.withLocation;
    }

    ctx.patchState({ searchedImagesLoading: true });

    return this.projectImageService.searchImages(action.projectId, newQuery).pipe(
      tap((result) => {
        ctx.patchState({ searchedImagesLoading: false });
        ctx.patchState({ currentSearchedImagesQuery: newQuery });
        ctx.patchState({ searchedImages: result });
      }),
      catchError((err) => {
        ctx.patchState({ searchedImagesLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(SetProjectImageSearchQuery)
  public projectSearchImagesQuery(
    ctx: StateContext<ProjectImageAnalyticsStateModel>,
    action: SetProjectImageSearchQuery,
  ) {
    const query = {
      ...ctx.getState().searchedImagesQuery,
      ...formatQueryFilterPayload<ProjectSearchImagesQuery>(action.query),
    };

    ctx.setState(
      patch<ProjectImageAnalyticsStateModel>({
        searchedImagesQuery: query,
      }),
    );
  }

  @Action(GetProjectImageUploadSession)
  public getProjectImageUploadSession(
    ctx: StateContext<ProjectImageAnalyticsStateModel>,
    action: GetProjectImageUploadSession,
  ) {
    const page = ctx.getState().uploadSessionsQuery.page;
    const count = ctx.getState().uploadSessionsCount;
    const sessionsLength = ctx.getState().uploadSessions.length;

    if (count > 0 && count === sessionsLength && page !== 0) {
      return EMPTY;
    }

    ctx.patchState({ uploadSessionsLoading: true });
    return this.projectImageService.getUploadSession(action.projectId, ctx.getState().uploadSessionsQuery).pipe(
      tap((result) => {
        ctx.patchState({ uploadSessionsLoading: false });
        ctx.patchState({
          uploadSessions: page === 0 ? result.items : [...ctx.getState().uploadSessions, ...result.items],
          uploadSessionsCount: result.totalCount,
        });
      }),
      catchError((err) => {
        ctx.patchState({ uploadSessionsLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(SetProjectImageUploadSessionQuery)
  public setUploadSessionQuery(
    ctx: StateContext<ProjectImageAnalyticsStateModel>,
    action: SetProjectImageUploadSessionQuery,
  ) {
    const currentPage = this.getSessionsPageCount(ctx);
    const newPage = currentPage <= action.query.page ? action.query.page : currentPage;
    const query = {
      ...action.query,
      page: action.query.page === 0 ? action.query.page : newPage,
    };

    ctx.patchState({ uploadSessionsQuery: query });
  }

  @Action(SetProjectImageUploadSession)
  public setProjectImageUploadSession(
    ctx: StateContext<ProjectImageAnalyticsStateModel>,
    action: SetProjectImageUploadSession,
  ) {
    return this.projectImageService.setUploadSession(action.projectId, action.payload).pipe(
      tap((result) => {
        ctx.setState(
          patch({
            uploadSessions: insertItem(result),
            uploadSessionsCount: ctx.getState().uploadSessionsCount + 1,
          }),
        );
      }),
    );
  }

  @Action(UpdateProjectImageStatus)
  updateProjectImageStatus(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: UpdateProjectImageStatus) {
    return this.projectImageService.updateImageStatus(action.projectId, action.imageId, action.payload).pipe(
      tap((result) => {
        // if we get status Uploaded but image is already Processed or ProcessedWithError,
        // don't update in the store (socket can be faster than request response)
        const findSession = ctx
          .getState()
          .uploadSessions.find((us) => !!us.medias.find((image) => image.id === action.imageId));
        if (findSession) {
          const image = findSession.medias.find((img) => img.id === action.imageId);
          if (
            image &&
            [ProjectImageStatus.Processed, ProjectImageStatus.ProcessedWithError].includes(image.status) &&
            action.payload.status === ProjectImageStatus.Uploaded
          ) {
            return EMPTY;
          }
        }

        ctx.setState(
          patch({
            uploadSessions: updateItem(
              (item) => item.id === result.uploadSessionId,
              patch({ medias: updateItem((image) => image.id === result.id, result) }),
            ),
          }),
        );
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(UpdateProjectImage)
  updateProjectImage(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: UpdateProjectImage) {
    return this.projectImageService.updateImage(action.projectId, action.imageId, action.payload).pipe(
      tap((result) => {
        ctx.setState(
          patch({
            selectedImage: ifNotNull(
              patch({
                ...ctx.getState().selectedImage,
                ...result,
              }),
            ),
          }),
        );

        if (action.showAlert) {
          this.notifierService.success('Image updated.', {
            autoClose: true,
            keepAfterRouteChange: true,
          });
        }
      }),
      catchError((err) => {
        if (action.showAlert) {
          this.notifierService.error('Image update failed.', {
            autoClose: true,
            keepAfterRouteChange: true,
          });
        }

        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(BulkUpdateProjectImage)
  bulkUpdateProjectImage(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: BulkUpdateProjectImage) {
    ctx.patchState({ bulkUpdateLoading: true });
    return this.projectImageService.bulkUpdate(action.projectId, action.payload).pipe(
      tap(() => {
        ctx.patchState({ bulkUpdateLoading: false });
        const updatedImages = ctx.getState().images.map((image) => {
          const payload = action.payload.find((p) => p.id === image.id) || {};
          return { ...image, ...payload };
        });

        ctx.patchState({ images: updatedImages });

        this.notifierService.success('Images updated.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }),
      catchError((err) => {
        ctx.patchState({ bulkUpdateLoading: false });
        this.notifierService.error('Images update failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });

        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(UpdateProjectImageInSession)
  updateProjectImageInSession(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: UpdateProjectImageInSession) {
    ctx.setState(
      patch({
        uploadSessions: updateItem(
          (item) => item.id === action.payload.uploadSessionId,
          patch({ medias: updateItem((image) => image.id === action.payload.id, action.payload) }),
        ),
      }),
    );
  }

  @Action(GetProjectImages)
  getImages(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetProjectImages) {
    const currentQuery = ctx.getState().imagesQuery;
    const newQuery = {
      ...currentQuery,
      yearMonth: currentQuery.yearMonth?.map((ym) => {
        if (ym === 'nodate') {
          return null;
        }
        return ym;
      }),
    } as ProjectImagesQuery;

    if (newQuery.withLocation === 'all') {
      delete newQuery.withLocation;
    }

    if (newQuery.page > 0 && ctx.getState().images.length) {
      newQuery.page = 0;
    }

    if (newQuery.page === 0) {
      ctx.patchState({ images: [] });
    }

    ctx.patchState({ imagesLoading: true });
    return this.projectImageService.getMedias(action.projectId, newQuery).pipe(
      tap((result) => {
        ctx.patchState({ imagesLoading: false });
        ctx.patchState({ images: result.items, imagesTotalCount: result.totalCount });
        ctx.patchState({ imagesQuery: newQuery });
      }),
      catchError((err) => {
        ctx.patchState({ imagesLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(GetMoreProjectImages)
  getMoreProjectImages(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetMoreProjectImages) {
    const state = ctx.getState();

    if (!state.images.length || state.images.length >= state.imagesTotalCount) {
      return;
    }

    const currentQuery = state.imagesQuery;
    const newQuery = {
      ...currentQuery,
      yearMonth: currentQuery.yearMonth?.map((ym) => {
        if (ym === 'nodate') {
          return null;
        }
        return ym;
      }),
    } as ProjectImagesQuery;

    ctx.patchState({ imagesLoadingMore: true });
    newQuery.page = state.imagesQuery.page + 1;

    if (newQuery.withLocation === 'all') {
      delete newQuery.withLocation;
    }

    return this.projectImageService.getMedias(action.projectId, newQuery).pipe(
      tap((result) => {
        ctx.patchState({ imagesLoadingMore: false });
        if (result.items.length) {
          ctx.patchState({
            images: [...ctx.getState().images, ...result.items],
            imagesQuery: { ...ctx.getState().imagesQuery, page: newQuery.page },
          });
        }
      }),
      catchError((err) => {
        ctx.patchState({ imagesLoadingMore: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(SetProjectImagesQuery)
  setProjectImageQuery(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: SetProjectImagesQuery) {
    ctx.setState(
      patch<ProjectImageAnalyticsStateModel>({
        imagesQuery: formatQueryFilterPayload<ProjectImagesQuery>(action.query),
      }),
    );
  }

  @Action(SaveSettings)
  saveSettings(_ctx: StateContext<ProjectImageAnalyticsStateModel>, action: SaveSettings) {
    return this.projectImageService.saveSettings(action.payload).pipe(
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(GetSettings)
  getSettings(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetSettings) {
    ctx.patchState({ settingsLoading: true });
    return this.projectImageService.getSettings(action.payload).pipe(
      tap((result) => {
        ctx.patchState({ settings: result.items });
        ctx.patchState({ settingsLoading: false });
        ctx.patchState({ settingsProjectId: action.payload.projectId });
      }),
      catchError((err) => {
        ctx.patchState({ settingsLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(UpdateProjectImageObject)
  updateProjectImageObject(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: UpdateProjectImageObject) {
    return this.projectImageService
      .updateObject(action.projectId, action.imageId, action.detectedObjectId, action.payload)
      .pipe(
        tap((result) => {
          ctx.setState(
            patch({
              selectedImage: ifNotNull(
                patch({
                  detectedObjects: ifNotNull(updateItem((object) => object.id === action.detectedObjectId, result)),
                }),
              ),
            }),
          );
          this.notifierService.success('Object updated.', {
            autoClose: true,
            keepAfterRouteChange: true,
          });
        }),
        catchError((err) => {
          this.notifierService.error('Object update failed.', {
            autoClose: true,
            keepAfterRouteChange: true,
          });

          return throwError(() => new Error(err));
        }),
      );
  }

  @Action(DeleteProjectImageObject)
  deleteProjectImageObject(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: DeleteProjectImageObject) {
    return this.projectImageService.deleteObject(action.projectId, action.imageId, action.detectedObjectId).pipe(
      tap(() => {
        ctx.setState(
          patch({
            selectedImage: ifNotNull(
              patch({
                detectedObjects: ifNotNull(removeItem((object) => object.id === action.detectedObjectId)),
              }),
            ),
          }),
        );
        this.notifierService.success('Object deleted.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }),
      catchError((err) => {
        this.notifierService.error('Object deletion failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });

        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(UpdateProjectImageTag)
  updateProjectImageTag(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: UpdateProjectImageTag) {
    return this.projectImageService.updateTag(action.projectId, action.imageId, action.mediaTagId, action.payload).pipe(
      tap((result) => {
        ctx.setState(
          patch({
            selectedImage: ifNotNull(
              patch({
                tags: ifNotNull(
                  updateItem(
                    (tag) => tag.mediaTagId === action.mediaTagId,
                    (tag) => {
                      return { ...tag, ...result };
                    },
                  ),
                ),
              }),
            ),
          }),
        );
        this.notifierService.success('Tag updated.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }),
      catchError((err) => {
        this.notifierService.error('Tag update failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });

        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(DeleteProjectImageTag)
  deleteProjectImageTag(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: DeleteProjectImageTag) {
    return this.projectImageService.deleteTag(action.projectId, action.imageId, action.mediaTagId).pipe(
      tap(() => {
        ctx.setState(
          patch({
            selectedImage: ifNotNull(
              patch({
                tags: ifNotNull(removeItem((tag) => tag.mediaTagId === action.mediaTagId)),
              }),
            ),
          }),
        );
        this.notifierService.success('Tag deleted.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }),
      catchError((err) => {
        this.notifierService.error('Tag deletion failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });

        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(AddProjectImageTag)
  addProjectImageTag(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: AddProjectImageTag) {
    return this.projectImageService.addTag(action.projectId, action.imageId, action.tagId).pipe(
      tap((result) => {
        ctx.setState(
          patch({
            selectedImage: ifNotNull(
              patch({
                tags: ifNotNull(append([result])),
              }),
            ),
          }),
        );
        this.notifierService.success('Tag added.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }),
      catchError((err) => {
        this.notifierService.error('Adding tag failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });

        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(GetProjectImage)
  getProjectImage(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetProjectImage) {
    ctx.patchState({ selectedImageLoading: true });
    return this.projectImageService.getImage(action.projectId, action.imageId).pipe(
      tap((result) => {
        ctx.patchState({ selectedImageLoading: false });
        ctx.patchState({ selectedImage: result });
      }),
      catchError((err) => {
        ctx.patchState({ selectedImageLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(DeleteProjectImage)
  deleteProjectImage(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: DeleteProjectImage) {
    return this.projectImageService.deleteImage(action.projectId, action.imageId).pipe(
      tap((result) => {
        ctx.patchState({ selectedImage: null });
        ctx.setState(patch({ images: removeItem((image) => image.id === result.id) }));
      }),
      catchError((err) => {
        this.notifierService.error('Image deletion failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(DeleteProjectImages)
  deleteProjectImages(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: DeleteProjectImages) {
    return this.projectImageService.bulkDelete(action.projectId, action.imageIds).pipe(
      tap(() => {
        ctx.patchState({ selectedImage: null });
        ctx.setState(patch({ images: ctx.getState().images.filter((image) => !action.imageIds.includes(image.id)) }));
        ctx.setState(
          patch({ selectedImagesIds: ctx.getState().selectedImagesIds.filter((id) => !action.imageIds.includes(id)) }),
        );
        this.notifierService.success('Images deleted.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }),
      catchError((err) => {
        this.notifierService.error('Image deletion failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(GetProjectImageTags)
  getProjectImageTags(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetProjectImageTags) {
    ctx.patchState({ selectedImageTagsLoading: true });
    return this.projectImageService.getTags(action.projectId, action.imageId, action.payload).pipe(
      tap((result) => {
        ctx.patchState({ selectedImageTagsLoading: false });
        ctx.patchState({ selectedImageTags: result });
      }),
      catchError((err) => {
        ctx.patchState({ selectedImageTagsLoading: false });
        ctx.patchState({ selectedImageTags: [] });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(GetProjectImageAudit, { cancelUncompleted: true })
  getProjectImageAudit(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetProjectImageAudit) {
    ctx.patchState({ selectedImageAuditLoading: true });
    return this.projectImageService.getProjectImageAudit(action.projectId, action.imageId).pipe(
      tap((result) => {
        ctx.patchState({ selectedImageAuditLoading: false });
        ctx.patchState({ selectedImageAudit: result });
      }),
      catchError((err) => {
        ctx.patchState({ selectedImageAuditLoading: false });
        ctx.patchState({ selectedImageAudit: { items: [], totalCount: 0 } });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(RevertProjectImageAudit)
  revertProjectImageAudit(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: RevertProjectImageAudit) {
    return this.projectImageService.revertProjectImageAudit(action.projectId, action.audit.id).pipe(
      tap((result) => {
        ctx.setState(
          patch({
            selectedImageAudit: patch({
              items: removeItem((item) => item.id === result.id),
              totalCount: ctx.getState().selectedImageAudit.totalCount - 1,
            }),
          }),
        );
      }),
      catchError((err) => {
        this.notifierService.error('Reverting failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(UpdateSelectedImageIds)
  updateSelectedImageIds(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: UpdateSelectedImageIds) {
    ctx.patchState({ selectedImagesIds: action.ids });
  }

  @Action(SetLastCheckedId)
  setLastCheckedId(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: SetLastCheckedId) {
    ctx.patchState({ lastCheckedId: action.id });
  }

  @Action(SetLastProjectId)
  setLastProjectId(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: SetLastProjectId) {
    ctx.patchState({ lastProjectId: action.id });
  }

  @Action(GetImagesByLink)
  getImagesByLink(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetImagesByLink) {
    ctx.patchState({ imagesLoading: true });
    return this.projectImageService.getImagesByLink(action.projectId, action.imageLinkId).pipe(
      tap((result) => {
        ctx.patchState({ imagesLoading: false });
        ctx.patchState({ images: result.items, imagesTotalCount: result.totalCount });
      }),
      catchError((err) => {
        ctx.patchState({ imagesLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(CreateImagesLink)
  createImagesLink(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: CreateImagesLink) {
    if (isEqual(ctx.getState().imageLinkIds, action.ids)) {
      return;
    }

    return this.projectImageService.createImagesLink(action.projectId, action.ids).pipe(
      tap((result) => {
        ctx.patchState({ imageLinkIds: action.ids });
        ctx.patchState({ imagesLink: result.id });
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(ZipProjectImages)
  zipProjectImages(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: ZipProjectImages) {
    ctx.patchState({ creatingZipLoading: true });
    return this.projectImageService
      .createZip(action.projectId, { mediaIds: action.ids, splitCount: action.splitCount })
      .pipe(
        tap((result) => {
          ctx.patchState({ creatingZipLoading: false });
          ctx.setState(
            patch({
              zipFiles: [...ctx.getState().zipFiles, ...result.items],
            }),
          );
          this.notifierService.success(
            'Zipping images in progress. Download will start once the zip file is created.',
            {
              autoClose: true,
              keepAfterRouteChange: true,
            },
          );
        }),
        catchError((err) => {
          ctx.patchState({ creatingZipLoading: false });
          return throwError(() => new Error(err));
        }),
      );
  }

  @Action(GetZipList)
  getZipList(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetZipList) {
    const page = ctx.getState().zipFilesQuery.page;
    const count = ctx.getState().zipFilesTotalCount;
    const sessionsLength = ctx.getState().zipFiles.length;

    if (count > 0 && count === sessionsLength && page !== 0) {
      return EMPTY;
    }

    ctx.patchState({ zipFilesLoading: true });
    return this.projectImageService.getZips(action.projectId, ctx.getState().zipFilesQuery).pipe(
      tap((result) => {
        ctx.patchState({ zipFilesLoading: false });
        ctx.patchState({
          zipFiles: page === 0 ? result.items : [...ctx.getState().zipFiles, ...result.items],
          zipFilesTotalCount: result.totalCount,
        });
      }),
      catchError((err) => {
        ctx.patchState({ zipFilesLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(GetZipFile)
  getZipFile(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetZipFile) {
    ctx.patchState({ zipFileLoading: true });
    return this.projectImageService.getZip(action.projectId, action.zipId).pipe(
      tap((result) => {
        ctx.patchState({ zipFileLoading: false });
        const findZip = ctx.getState().zipFiles.find((file) => file.id === action.zipId);
        ctx.setState(
          patch({ zipFiles: findZip ? updateItem((item) => item.id === action.zipId, result) : insertItem(result) }),
        );
        ctx.patchState({
          zipFilesTotalCount: findZip ? ctx.getState().zipFilesTotalCount : ctx.getState().zipFilesTotalCount + 1,
        });
      }),
      catchError((err) => {
        ctx.patchState({ zipFileLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(SetProjectImageZipsQuery)
  setProjectImageZipsQuery(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: SetProjectImageZipsQuery) {
    const currentPage = this.getZipsPageCount(ctx);

    if (ctx.getState().zipFiles.length === ctx.getState().zipFilesTotalCount) {
      return;
    }

    const newPage = currentPage <= action.query.page ? action.query.page : currentPage;
    const query = {
      ...action.query,
      page: action.query.page === 0 ? action.query.page : newPage,
    };

    ctx.patchState({ zipFilesQuery: query });
  }

  @Action(AddFollower)
  addFollower(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: AddFollower) {
    ctx.patchState({ followersLoading: true });
    return this.projectImageService.addFollower(action.projectId, action.imageId, action.payload).pipe(
      tap((result) => {
        ctx.patchState({ followersLoading: false });
        ctx.setState(patch({ selectedImage: ifNotNull(patch({ followers: append([result]) })) }));
      }),
      catchError((err) => {
        ctx.patchState({ followersLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(RemoveFollower)
  removeFollower(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: RemoveFollower) {
    ctx.patchState({ followersLoading: true });
    return this.projectImageService.removeFollower(action.projectId, action.imageId, action.followerId).pipe(
      tap(() => {
        ctx.patchState({ followersLoading: false });
        ctx.setState(
          patch({
            selectedImage: ifNotNull(patch({ followers: removeItem((item) => item.id === action.followerId) })),
          }),
        );
      }),
      catchError((err) => {
        ctx.patchState({ followersLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(GetComments)
  getComments(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetComments) {
    if (
      ctx.getState().comments.length === ctx.getState().commentsTotalCount &&
      ctx.getState().commentsTotalCount !== 0
    ) {
      return;
    }

    ctx.patchState({ commentsLoading: true });

    if (action.imageId !== ctx.getState().selectedImage?.id) {
      ctx.patchState({ comments: [] });
    }

    const nextPage = Math.ceil(ctx.getState().comments.length / 10);

    return this.projectImageService
      .getComments(action.projectId, action.imageId, { ...queryFilter, page: nextPage })
      .pipe(
        tap((result) => {
          ctx.patchState({ commentsLoading: false, commentsTotalCount: result.totalCount });
          ctx.setState(patch({ comments: append([...result.items]) }));
        }),
        catchError((err) => {
          ctx.patchState({ commentsLoading: false });
          return throwError(() => new Error(err));
        }),
      );
  }

  @Action(AddComment)
  addComment(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: AddComment) {
    ctx.patchState({ commentsLoading: true });
    return this.projectImageService.addComment(action.projectId, action.imageId, action.payload).pipe(
      tap((result) => {
        ctx.patchState({ commentsLoading: false });
        ctx.setState(patch({ comments: append([result]) }));
      }),
      catchError((err) => {
        ctx.patchState({ commentsLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(UpdateComment)
  updateComment(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: UpdateComment) {
    ctx.patchState({ commentsLoading: true });
    return this.projectImageService
      .updateComment(action.projectId, action.imageId, action.commentId, action.payload)
      .pipe(
        tap((result) => {
          ctx.patchState({ commentsLoading: false });
          ctx.setState(patch({ comments: updateItem((item) => item.id === action.commentId, result) }));
        }),
        catchError((err) => {
          ctx.patchState({ commentsLoading: false });
          return throwError(() => new Error(err));
        }),
      );
  }

  @Action(RemoveComment)
  removeComment(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: RemoveComment) {
    ctx.patchState({ commentsLoading: true });
    return this.projectImageService.removeComment(action.projectId, action.imageId, action.commentId).pipe(
      tap(() => {
        ctx.patchState({ commentsLoading: false });
        ctx.setState(patch({ comments: removeItem((item) => item.id === action.commentId) }));
      }),
      catchError((err) => {
        ctx.patchState({ commentsLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  private getSessionsPageCount(ctx: StateContext<ProjectImageAnalyticsStateModel>) {
    return Math.ceil(ctx.getState().uploadSessions.length / ctx.getState().uploadSessionsQuery.pageSize) - 1;
  }

  private getZipsPageCount(ctx: StateContext<ProjectImageAnalyticsStateModel>) {
    return Math.ceil(ctx.getState().zipFiles.length / ctx.getState().zipFilesQuery.pageSize) - 1;
  }

  private async handleFileUpload(
    ctx: StateContext<ProjectImageAnalyticsStateModel>,
    action: SetProjectImageUploadSession,
  ) {
    const totalCount = action.fileList.length;
    const maxConcurrentUploads = 5; // Set maximum concurrent uploads to 5

    // Helper function to handle individual file upload
    const uploadFile = async (list: ProjectImagesFileList, sas: BlobStorageSasModel) => {
      if (list.error) {
        ctx.dispatch(
          new UpdateProjectImageStatus(action.projectId, list.id, {
            status: ProjectImageStatus.InitializedWithError,
            errorType: list.error,
          }),
        );
        return;
      }

      const file = list.file;
      const ext = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
      const name = `${ctx.getState().uploadFolder}/${list.id}.${ext}`;

      try {
        await this.azureBlobStorageService.uploadFile(sas.url, list.file, name).response;
        ctx.dispatch(new UpdateProjectImageStatus(action.projectId, list.id, { status: ProjectImageStatus.Uploaded }));
      } catch (_) {
        ctx.dispatch(
          new UpdateProjectImageStatus(action.projectId, list.id, {
            status: ProjectImageStatus.UploadFailed,
            errorType: ProjectImageErrorType.UploadFailed,
          }),
        );
      }
    };

    // Process the uploads in chunks of 5 files
    for (let i = 0; i < totalCount; i += maxConcurrentUploads) {
      const fileChunk = action.fileList.slice(i, i + maxConcurrentUploads); // Get a chunk of 5 files
      const sas = await firstValueFrom(this.blobStorageSasService.getSasUrlForProject(action.projectId));
      const uploadPromises = fileChunk.map((list) => uploadFile(list, sas)); // Create a list of upload promises

      await Promise.all(uploadPromises); // Wait for all the uploads in the current chunk to finish
    }
  }
}
