import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { fadeInRightOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { Subscription } from 'rxjs';
import { Alert, AlertType, NotifierService, SvgIconDirective, SvgIcons } from 'ngx-q360-lib';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';

import { NotificationComponent } from '../notifications/notification/notification.component';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [fadeInRightOnEnterAnimation(), fadeOutOnLeaveAnimation()],
  imports: [NotificationComponent, NgClass, ExtendedModule, SvgIconDirective],
})
export class AlertComponent implements OnInit, OnDestroy {
  protected readonly alertType = AlertType;
  id = 'default-alert';
  fade = true;

  alerts: Alert[] = [];
  alertSubscription!: Subscription;
  routeSubscription!: Subscription;

  private router = inject(Router);
  private notifierService = inject(NotifierService);

  ngOnInit(): void {
    this.alertSubscription = this.notifierService.onAlert(this.id).subscribe((alert) => {
      // clear alerts when an empty alert is received
      if (!alert?.message) {
        // filter out alerts without 'keepAfterRouteChange' flag
        this.alerts = this.alerts.filter((x) => x.keepAfterRouteChange);
        return;
      }

      // add alert to array
      this.alerts.push(alert);

      // auto close alert if required
      if (alert.autoClose) {
        setTimeout(() => this.removeAlert(alert), 3000);
      }
    });

    // clear alerts on location change
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.notifierService.clear();
      }
    });
  }

  ngOnDestroy(): void {
    // unsubscribe to avoid memory leaks
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  removeAlert(alert: Alert) {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) {
      return;
    }

    if (this.fade) {
      // fade out alert
      // this.alerts!.find((x: Alert) => x === alert).fade = true;
      const tempAlert = this.alerts.find((x) => x === alert);
      if (tempAlert) {
        tempAlert.fade = true;
      }

      // remove alert after faded out
      setTimeout(() => {
        this.alerts = this.alerts.filter((x) => x !== alert);
      }, 250);
    } else {
      // remove alert
      this.alerts = this.alerts.filter((x) => x !== alert);
    }
  }

  cssClass(alert: Alert): string | undefined {
    if (!alert) {
      return;
    }

    const classes = ['alert', 'alert-dismissable'];

    const alertTypeClass: Record<AlertType, string> = {
      [AlertType.Success]: 'alert-success',
      [AlertType.Error]: 'alert-danger',
      [AlertType.Info]: 'alert-info',
      [AlertType.Warning]: 'alert-warning',
      [AlertType.Notification]: 'alert-notification',
    };

    classes.push(alertTypeClass[alert.type]);

    if (alert.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }

  svgIcon(alert: Alert) {
    const alertTypeClass: Record<string, { icon: SvgIcons; color: string }> = {
      [AlertType.Success]: { icon: 'framed-success-checkmark', color: 'status-done' },
      [AlertType.Error]: { icon: 'framed-x-circled', color: 'red-color' },
      [AlertType.Info]: { icon: 'framed-info', color: 'primary-colors-accent-blue' },
      [AlertType.Warning]: { icon: 'framed-alert-triangle', color: 'primary-colors-sunflower' },
    };

    return alertTypeClass[alert.type];
  }
}
