import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import {
  AvatarComponent,
  FilterMainProjectsPipePipe,
  FilterPipe,
  FlexDirective,
  FlexGapDirective,
  FlexLayoutAlignDirective,
  LoadMoreComponent,
  NothingToShowIcons,
  SearchInputComponent,
  SvgIconDirective,
  TestIdDirective,
} from 'ngx-q360-lib';
import { select } from 'ngx-q360-lib';
import { ProjectSelectors } from '@app/store/project/projects.selectors';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { CreateMainProject, LoadMainProjects, PinProject } from '@app/store/project/project.actions';
import { MainProjectModel, MyProjectBasicModel } from 'ngx-q360-lib';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CreateMainProjectDialogComponent } from '@project/create-main-project-dialog/create-main-project-dialog.component';
import { CreateProjectDialogComponent } from '@global-shared/dialogs/create-project-dialog/create-project-dialog.component';
import { combineLatestWith } from 'rxjs';
import { MainProjectType } from 'ngx-q360-lib';
import { mapMyProjectToMyBasicProject, selectProject } from '@core/helpers/project.helper';
import { PinFailedDialogComponent } from '@project/project-list-menu/pin-failed-dialog/pin-failed-dialog.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { UserOnboardingSettingsEnum } from 'ngx-q360-lib';
import { TestIds } from 'ngx-q360-lib';
import { UserSelectors } from '@app/store/user/user.selectors';
import { MainProjectSettingsComponent } from '@global-shared/dialogs/main-project-settings/main-project-settings.component';
import { SelectedProjectSelectors } from '@project/store/selected-project/selected-project.selectors';
import { UserRole } from 'ngx-q360-lib';
import { UserOnboardingGuideDirective } from '@global-shared/directives/user-onboarding-guide.directive';
import { NothingToShowComponent } from '@global-shared/components/nothing-to-show/nothing-to-show.component';
import { NgStyle } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-project-list-menu',
  templateUrl: './project-list-menu.component.html',
  styleUrls: ['./project-list-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SearchInputComponent,
    TestIdDirective,
    UserOnboardingGuideDirective,
    FlexLayoutAlignDirective,
    FlexGapDirective,
    FlexDirective,
    FilterMainProjectsPipePipe,
    LoadMoreComponent,
    FilterPipe,
    AvatarComponent,
    NothingToShowComponent,
    NgStyle,
    MatTooltip,
    ReactiveFormsModule,
    MatDialogContent,
    SvgIconDirective,
  ],
})
export class ProjectListMenuComponent implements OnInit {
  protected readonly testIds = TestIds;
  protected readonly nothingToShowIcons = NothingToShowIcons;
  protected readonly mainProjectType = MainProjectType;
  private destroyRef = inject(DestroyRef);

  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly matDialog = inject(MatDialog);
  private readonly matDialogRef = inject(MatDialogRef<ProjectListMenuComponent>);
  private readonly actions = inject(Actions);
  private readonly dialogData = inject<{ mainProjectId: string }>(MAT_DIALOG_DATA);

  readonly mainProjects$ = select(ProjectSelectors.mainProjects);
  readonly selectedProject$ = select(SelectedProjectSelectors.getSlices.project);

  selectedProject = signal<MyProjectBasicModel | undefined>(undefined);
  selectedMainProject = signal<MainProjectModel | undefined>(undefined);
  searchValue = signal<string>('');
  selectedProjects = signal<MyProjectBasicModel[]>([]);
  filteredSearchProjects = computed(() =>
    this.selectedProjects().filter(
      (proj) =>
        proj.name.toLowerCase().includes(this.searchValue().toLowerCase()) ||
        proj.code.toLowerCase().includes(this.searchValue().toLowerCase()),
    ),
  );
  myOrg = toSignal(select(UserSelectors.user));
  sasUrl = toSignal(select(UserSelectors.sasRealUrlForImages), { initialValue: null });
  canCreateMainProject = toSignal(select(UserSelectors.canCreateProject));
  showNewProjectButton = computed(() => {
    //co-owner org can create only one project without an owner
    if (
      this.selectedMainProject()?.coOwnerOrganizationId === this.myOrg()?.organizationId &&
      !this.selectedMainProject()?.ownerOrganizationId &&
      this.selectedProjects().length > 0
    ) {
      return false;
    }

    const roles = this.userMainProjectRoles().find((roles) => roles.mainProjectId === this.selectedMainProject()?.id);
    // you have to have admin permissions and to be owner or co-owner of main project
    // in order to create a new project
    return (roles?.isMyOrgOwner || roles?.isMyOrgCoOwner) && (this.isUserOrgAdmin() || roles?.isMainProjectAdmin);
  });

  userMainProjectRoles = toSignal(select(ProjectSelectors.userMainProjectRoles), { initialValue: [] });
  user = toSignal(select(UserSelectors.user));
  isUserOrgAdmin = computed(() => this.user()?.roles.includes(UserRole.OrgAdmin));
  mainProjects = toSignal(this.mainProjects$, { initialValue: [] });
  mainProjectsLoading = toSignal(select(ProjectSelectors.mainProjectsLoading), { initialValue: false });
  pinnedProjects = toSignal(select(ProjectSelectors.pinnedProjects), { initialValue: [] });
  myProjects = toSignal(select(ProjectSelectors.myProjectsBasicInProgress), { initialValue: [] });

  config!: MatDialogConfig;
  searchControl = new FormControl('');
  get userOnboardingSettingsEnum(): typeof UserOnboardingSettingsEnum {
    return UserOnboardingSettingsEnum;
  }

  @ViewChild('mainProjectsListElement', { static: false }) mainProjectsListElement!: ElementRef<HTMLDivElement>;

  ngOnInit() {
    this.store.dispatch(new LoadMainProjects());

    this.actions.pipe(ofActionSuccessful(CreateMainProject)).subscribe(() => {
      const lastMainProject = this.store.selectSnapshot(ProjectSelectors.lastCreatedMainProject);
      if (lastMainProject) {
        this.selectMainProject(lastMainProject);
      }
    });

    this.selectedProject$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result) => {
      if (!this.dialogData) {
        this.selectedMainProject.set(result?.mainProject);
        if (result) {
          this.selectedProject.set(result);
          this.setSelectedProjects();
        }
      }
    });

    this.searchControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged(), debounceTime(300))
      .subscribe((value) => {
        this.searchValue.set(value || '');
        if (value) {
          this.selectedProjects.set(this.myProjects());
        } else {
          this.selectedProjects.set(
            this.myProjects().filter((proj) => proj.mainProject.id === this.selectedProject()?.mainProject?.id),
          );
        }
      });

    this.mainProjects$.subscribe((result) => {
      if (this.dialogData) {
        const findMainProject = result.find((mp) => mp.id === this.dialogData.mainProjectId);
        if (findMainProject) {
          this.selectedMainProject.set(findMainProject);
          this.selectedProjects.set(
            this.myProjects().filter((proj) => proj.mainProject.id === this.dialogData.mainProjectId),
          );
        }
      }
      setTimeout(() => {
        const selectedMainProjectElement = this.mainProjectsListElement?.nativeElement.getElementsByClassName(
          this.selectedMainProject()?.id || 'none',
        )[0];
        selectedMainProjectElement?.scrollIntoView({ block: 'center' });
      }, 100);
    });
  }

  setSelectedProjects() {
    this.selectedProjects.set(
      this.myProjects().filter((proj) => proj.mainProject.id === this.selectedProject()?.mainProject?.id),
    );
  }

  selectMainProject(mainProject: MainProjectModel) {
    this.selectedMainProject.set(mainProject);
    this.selectedProjects.set(this.myProjects().filter((proj) => proj.mainProject.id === mainProject.id));
    if (this.searchControl.value) {
      this.searchControl.setValue('', { emitEvent: false });
    }
  }

  selectProject(project: MyProjectBasicModel): void {
    this.matDialogRef.close();
    selectProject(project.id, this.selectedProject()?.id, this.store, this.router);
  }

  navigateToOrg() {
    this.matDialogRef.close();
    void this.router.navigate([`/pages/organisation/projects-kan-ban`], {
      queryParams: { search: this.selectedMainProject()?.name },
    });
  }

  onCreateMainProject() {
    this.matDialog.open(CreateMainProjectDialogComponent, {
      maxWidth: 768,
      width: '100%',
    });
  }

  onCreateProject() {
    const dialog = this.matDialog.open(CreateProjectDialogComponent, {
      maxWidth: 768,
      width: '100%',
      data: {
        selectedMainProject: this.selectedMainProject(),
      },
    });

    dialog
      .afterClosed()
      .pipe(combineLatestWith(this.store.select(ProjectSelectors.lastCreatedProject)))
      .subscribe(([modalResult, lastCreatedProject]) => {
        if (modalResult && lastCreatedProject) {
          this.matDialogRef.close();
          if (lastCreatedProject && this.selectedProjects().find((p) => p.id !== lastCreatedProject.id)) {
            this.selectedProjects.update((array) => [...array, mapMyProjectToMyBasicProject(lastCreatedProject)]);
          }
        }
      });
  }

  onPinProject(project: MyProjectBasicModel) {
    const pinnedProjects = this.store.selectSnapshot(ProjectSelectors.pinnedProjects);
    if (pinnedProjects.length === 3 && !pinnedProjects.map((p) => p.id).includes(project.id)) {
      this.matDialog.open(PinFailedDialogComponent, {
        maxWidth: 473,
      });
      return;
    }

    this.store.dispatch(new PinProject(project.id));
  }

  onOpenSettings(mainProject: MainProjectModel) {
    this.matDialog.open(MainProjectSettingsComponent, {
      maxWidth: '768px',
      width: '100%',
      data: {
        mainProject: mainProject,
      },
    });
  }
}
