import {
  EnvironmentProviders,
  ErrorHandler,
  Injectable,
  makeEnvironmentProviders,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AngularPlugin,
  ApplicationinsightsAngularpluginErrorService,
} from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, DistributedTracingModes, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class InsightsService {
  private angularPlugin = new AngularPlugin();
  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.instrumentationKey,
      extensions: [this.angularPlugin],
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
          errorServices: [new ErrorHandler()],
        },
      },
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
      correlationHeaderExcludePatterns: [
        /maps.googleapis\.com/i, // Exclude requests to Google Maps API
      ],
    },
  });

  // expose methods that can be used in components and services
  trackEvent = this.appInsights.trackEvent;
  trackTrace = this.appInsights.trackTrace;

  constructor(private router: Router) {
    this.setupTelemetryInitializer();
  }

  load() {
    this.appInsights.loadAppInsights();
  }

  private setupTelemetryInitializer() {
    this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      // Exclude SVG files from being sent as dependencies
      if (envelope.baseType === 'RemoteDependencyData' && envelope.baseData?.name?.endsWith('.svg')) {
        return false; // Prevent this telemetry item from being sent
      }
    });
  }
}

export function provideInsights(options: { enabled: boolean } = { enabled: true }): EnvironmentProviders {
  if (!options.enabled) {
    return makeEnvironmentProviders([]);
  }
  return makeEnvironmentProviders([
    InsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    provideAppInitializer(() => {
      const initializerFn = (
        (insightsService: InsightsService) => () =>
          insightsService.load()
      )(inject(InsightsService));
      return initializerFn();
    }),
  ]);
}
