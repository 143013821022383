import { ChangeDetectionStrategy, Component, inject, input, signal, output } from '@angular/core';
import {
  FlexGapDirective,
  FlexLayoutAlignDirective,
  NotificationCoOwnerInvited,
  NotificationOwnerInvited,
  NotificationType,
  SvgIconDirective,
} from 'ngx-q360-lib';
import { getNotificationDate } from '@app/shared/notifications-shared/components/notifications/notifications.utils';
import { ConfirmDialogComponent } from '@global-shared/dialogs/confirm-dialog/confirm-dialog.component';
import { ResolveMainProjectInvitation } from '@project/store/selected-project/selected-project.actions';
import { DeleteNotification } from '@app/store/notification/notification.actions';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';

import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';

@Component({
  selector: 'app-new-owner-invite-notification',
  templateUrl: './main-project-owner-invite-notification.component.html',
  styleUrls: ['../../notification/notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FlexGapDirective,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    FlexLayoutAlignDirective,
    MatTooltip,
    SvgIconDirective,
  ],
})
export class MainProjectOwnerInviteNotificationComponent {
  protected readonly notificationType = NotificationType;
  protected readonly getDate = getNotificationDate;

  private store = inject(Store);
  private matDialog = inject(MatDialog);

  notification = input.required<NotificationOwnerInvited | NotificationCoOwnerInvited>();
  popupMode = input<boolean>(false);
  unseenCount = input(0);

  deleteNotification = output<string>();
  clickNotification = output<void>();

  panelExpanded = signal<boolean>(false);

  onRejectInvitation() {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: 473,
      data: {
        title: 'Reject invitation?',
        text: 'Are you sure you want to reject this main project group invitation? You will not be able to access to the mentioned main project group.',
        cancelButtonText: 'Cancel',
        saveButtonText: 'Reject',
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new ResolveMainProjectInvitation(this.notification(), false));
        this.store.dispatch(new DeleteNotification({ notificationId: this.notification().id }));
      }
    });
  }

  onAcceptInvitation() {
    this.store.dispatch(new ResolveMainProjectInvitation(this.notification(), true));
    this.store.dispatch(new DeleteNotification({ notificationId: this.notification().id }));
  }

  onDeleteNotification(event: any): void {
    event.stopPropagation();
    this.deleteNotification.emit(this.notification().id);
  }

  markAsRead() {
    this.clickNotification.emit();
  }
}
