import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard {
  constructor(private authService: AuthService) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return (
      this.authService.canActivateProtectedRoutes$
        // eslint-disable-next-line no-console
        .pipe(tap((x) => console.log('You tried to go to ' + state.url + ' and this guard said ' + x)))
    );
  }
}
